
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
export default defineComponent({
  name: "highlight-pdf",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Detail Highlight PDF");
    });
  },
});
